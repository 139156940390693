<template>
	<div
		v-if="isNotLink"
		style="cursor: default"
		class="order-products-card"
	>
		<div class="order-products-card__image">
			<img :src="image" alt="Product preview">
		</div>
		<h3 class="order-products-card__title">
			{{ subtitle }} - {{ title }}
		</h3>
		<div class="order-products-card__count">
			{{ quantity }} шт.
		</div>
		<div class="order-products-card__price">
			{{ getTotalPrice }} руб.
		</div>
		<div
				class="order-products-card__remove"
				v-if="eventRemove"
				@click.prevent="onRemoveCartProduct"
		>
			<BaseIcon
					icon-class="order-products-card__icon"
					icon-name="close"
			/>
		</div>
	</div>
	<router-link
		v-else
		:to="{name: 'CatalogDetail', params: { id: productCode ? productCode : '/'}}"
		class="order-products-card"
	>
		<div class="order-products-card__image">
			<img :src="image" alt="Product preview">
		</div>
		<h3 class="order-products-card__title">
			{{ subtitle }} - {{ title }}
		</h3>
		<div class="order-products-card__count">
			{{ quantity }} шт.
		</div>
		<div class="order-products-card__price">
			{{ getTotalPrice }} руб.
		</div>
		<div
			class="order-products-card__remove"
			v-if="eventRemove"
			@click.prevent="onRemoveCartProduct"
		>
			<BaseIcon
				icon-class="order-products-card__icon"
				icon-name="close"
			/>
		</div>
	</router-link>
</template>

<script>
import { computed, toRefs } from "vue";
import BaseIcon from "@/components/Base/BaseIcon";

export default {
	name: "OrderCard",
	components: {
		BaseIcon
	},
	props: {
		productCode: {
			type: String,
			default: ''
		},
		productId: {
			type: Number,
			default: 0
		},
		image: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		quantity: {
			type: Number,
			default: 0
		},
		price: {
			type: String,
			default: ''
		},
		eventRemove: {
			type: Boolean,
			default: true
		},
		isNotLink: {
			type: Boolean,
			default: false
		}
	},
	setup(props, { emit }) {
		const { quantity } = toRefs(props);
		const { price } = toRefs(props);

		const getTotalPrice = computed(() => {
			return quantity.value * price.value;
		});

		const onRemoveCartProduct = () => {
			emit('onRemoveCartProduct');
		};

		return {
			quantity,
			price,
			getTotalPrice,
			onRemoveCartProduct
		}
	}
}
</script>