<template>
	<div class="counter">
		<div
			class="counter__button"
			@click="decrease"
		>
			<BaseIcon
				icon-name="minus"
				icon-class="counter__icon"
			/>
		</div>
		<div class="counter__text">
			{{ count }}
		</div>
		<div
			class="counter__button"
			@click="increase"
		>
			<BaseIcon
				icon-name="plus"
				icon-class="counter__icon"
			/>
		</div>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon";

export default {
	name: "BaseCounter",
	components: {
		BaseIcon
	},
	props: {
		count: {
			type: Number,
			default: 0
		}
	},
	setup(props, { emit }) {

		const increase = () => {
			emit('increase');
		}

		const decrease = () => {
			emit('decrease');
		}

		return {
			increase,
			decrease
		}
	}
}
</script>