<template>
	<div class="catalog-card">
		<router-link
			:to="{name: 'CatalogDetail', params: { id: productCode ? productCode : '/'}}"
			class="catalog-card__content"
		>
			<div class="catalog-card__image">
				<img :src="image" alt="Product preview">
			</div>
			<div class="catalog-card__text">
				<div class="catalog-card__price">
					{{ price }} руб.
				</div>
				<h3 class="catalog-card__title">
					<span>{{ subtitle }}</span>
					{{ title }}
				</h3>
			</div>
		</router-link>
		<div class="catalog-card__interactions">
			<BaseCounter
				v-if="isAddCart"
				:count="count"
				@increase="onClickIncrease"
				@decrease="onClickDecrease"
			/>
			<BaseButton
				v-else
				text="В корзину"
				@clickHandler="onClickButton"
			/>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BaseCounter from "@/components/Base/BaseCounter";

export default {
	name: "CatalogCard",
	components: {
		BaseButton,
		BaseCounter
	},
	props: {
		productCode: {
			type: String,
			default: ''
		},
		image: {
			type: String,
			default: ''
		},
		price: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		count: {
			type: Number,
			default: 0
		},
		isAddCart: {
			type: Boolean,
			default: false
		}
	},
	setup(props, { emit }) {

		const onClickButton = () => {
			emit('onClickButton');
		}

		const onClickIncrease = () => {
			emit('onClickIncrease');
		}

		const onClickDecrease = () => {
			emit('onClickDecrease');
		}

		return {
			onClickButton,
			onClickIncrease,
			onClickDecrease
		}
	}
}
</script>