<template>
	<svg
		:class="[
			'icon',
			iconClass
		]"
	>
		<use :xlink:href="'/assets/img/sprite.svg#' + iconName"></use>
	</svg>
</template>

<script>
export default {
	name: "BaseIcon",
	props: {
		iconName: {
			type: String,
			default: ''
		},
		iconClass: {
			type: String,
			default: ''
		}
	}
}
</script>