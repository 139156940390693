<template>
	<input
		ref="baseField"
		:type="fieldType"
        :value="modelValue"
		:required="required"
		@input="updateValue"
		:placeholder="fieldPlaceholder"
		:name="fieldName"
		:readonly="readonly"
		:class="[
			'field',
			{'is-focus-disabled': isFocusDisabled}
		]"
	>
</template>

<script>
import {ref} from "vue";

export default {
	name: "BaseField",
	props: {
		fieldName: {
			type: String,
			default: ''
		},
		fieldType: {
			type: String,
			default: ''
		},
		fieldPlaceholder: {
			type: String,
			default: ''
		},
		modelValue: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		isFocusDisabled: {
			type: Boolean,
			default: false
		}
	},
	setup(props, { emit }) {
		const updateValue = (event) => {
			emit('update:modelValue', event.target.value);
		};

		const baseField = ref();

		document.addEventListener('click', (e) => {
			if (baseField.value !== null) {
				if (e.target === baseField.value) {
					baseField.value.focus();
				} else {
					baseField.value.blur();
				}
			}
		});

		return {
			updateValue,
			baseField
		}
	}
}
</script>
