import { createStore } from 'vuex'

export default createStore({
    state: {
        products: [
            {
                id: 0,
                code: 'calm-rasslablenie',
                image: '/assets/img/products/calm-rasslablenie.png',
                price: '1200',
                subtitle: 'CALM',
                title: 'Расслабление',
                description: 'Вдохните расслабляющий пар с легким ароматом мяты, который успокоит ваш ум и приведет к умиротворению.',
                quantity: 1,
                isCart: false
            },
            {
                id: 1,
                code: 'wake-energy',
                image: '/assets/img/products/wake-energy.png',
                price: '1200',
                subtitle: 'WAKE',
                title: 'Энергия',
                description: 'Витамин B12 и кофеин помогут вам быстрее проснуться, и чувствовать себя бодро и энергично в течение дня, аминокислота L-теанин поддержит состояние концентрации.',
                quantity: 1,
                isCart: false
            },
            {
                id: 3,
                code: 'rise-kontsentratsiya',
                image: '/assets/img/products/rise-kontsentratsiya.png',
                price: '1200',
                subtitle: 'RISE',
                title: 'Концентрация',
                description: 'Витамины B6 и B12 во взаимодействии с таурином улучшают функции мозга и усиливают концентрацию и фокус внимания при решении сложных задач.',
                quantity: 1,
                isCart: false
            },
            {
                id: 4,
                code: 'glow-omolozhenie',
                image: '/assets/img/products/glow-omolozhenie.png',
                price: '1200',
                subtitle: 'GLOW',
                title: 'Омоложение',
                description: 'Он сочетает в себе коллаген и комплекс аминокислот - глутатион и L-карнитин для повышения упругости и эластичности тканей',
                quantity: 1,
                isCart: false
            },
            {
                id: 5,
                code: 'vita',
                image: '/assets/img/products/vita.png',
                price: '1200',
                subtitle: 'VITA',
                title: '',
                description: 'Витаминный коктейль для активной жизни. 5 самых важных витаминов которых не хватает: Коэнзим Q10, витамины C, D3, B12, A. Комплексный эффект для бодрости, легкости и солнечного настроения.',
                quantity: 1,
                isCart: false
            },
            {
                id: 6,
                code: 'zen',
                image: '/assets/img/products/zen.png',
                price: '1200',
                subtitle: 'ZEN',
                title: '',
                description: 'Глубокое сосредоточенное умиротворение, взгляд внутрь себя, восполнение энергии. ZEN сочетает в себе экстракт ромашки, пассифлоры и корня валерианы - снятие стресса в моменте, L-теанин - повышение стрессоустойчивости организма на длинной дистанции.',
                quantity: 1,
                isCart: false
            },
            {
                id: 7,
                code: 'combo',
                image: '/assets/img/products/combo.png',
                price: '6000',
                subtitle: 'COMBO',
                title: '',
                description: 'Попробуйте все 6 вкусов витаминного ингалятора LUVV Air и ощутите на себе уникальный эффект. Заказывая LUVV Air Combo, вы получаете все 6 ингаляторов со скидкой.',
                quantity: 0,
                isCart: false
            },
        ],
        cart: [],
        deliveryMethods: [
            {
                id: 0,
                type: 'pickup',
                name: 'Москва, Дербеневская наб., 7с22 (м. Павелецкая)',
                address: 'Самовывоз',
                price: 'Бесплатно'
            },
            {
                id: 1,
                type: 'delivery',
                name: 'В пределах Москвы, МКАД',
                address: 'Доставка',
                price: 'Бесплатно'
            },
            {
                id: 2,
                type: 'delivery',
                name: 'Московская область',
                address: 'Доставка',
                price: '290'
            },
            {
                id: 3,
                type: 'delivery',
                name: 'Санкт-Петербург, КАД',
                address: 'Доставка',
                price: '215'
            },
        ],
        user: {
            name: '',
            phone: '',
            email: '',
            city: '',
            street: '',
            appartment: '',
            delivery: {},
            amount: 0,
            products: {}
        },
        urlPay: '12'
    },
    mutations: {
        addCartProduct(state,product){
            if (product.isCart === false) {
                state.cart.push(product);
            }
            state.products.map(item => {
                if (item.id === product.id) {
                    item.isCart = true;
                    if (item.quantity === 0) {
                        item.quantity = 1;
                    }
                }
            });
        },
        removeCartProduct(state,id){
            state.cart = state.cart.filter((item)=> item.id !== id )

            state.products.map((item)=>{
                if ( item.id === id) {
                    item.isCart= false;
                    item.quantity = 0;
                }
            })
        },
        increase(state,id){
            state.products.map((product)=>{
                if ( product.id === id) {
                    product.quantity += 1
                }
            });
            state.cart.map((product)=>{
                if ( product.id === id) {
                    product.quantity += 1
                }
            });
        },
        decrease(state,id){
            state.cart.map((product) => {
                if ( product.id === id && product.quantity > 1) {
                    product.quantity -= 1;
                } else if (product.id === id && product.quantity === 1) {
                    product.quantity = 0;
                    product.isCart = false;
                    state.cart = state.cart.filter((item)=> item.id !== id );
                }
            });
            state.products.map((product) => {
                if ( product.id === id && product.quantity > 1) {
                    product.quantity -= 1;
                } else if (product.id === id && product.quantity === 1) {
                    product.quantity = 0;
                    product.isCart = false;
                    state.cart = state.cart.filter((item)=> item.id !== id );
                }
            });
        },
        setDeliveryMethod(state, item) {
            state.user.delivery = item;
        },
        setUserAddress(state, item) {
            state.user.address = item;
        },
        setUserInfo(state, item) {
            state.user.name = item.name;
            state.user.phone = item.phone;
            state.user.email = item.email;
            if (state.user.delivery.type === 'pickup') {
                state.user.city = 'Москва';
                state.user.street = 'Дербеневская наб. 7с22 (м. Павелецкая)';
                state.user.appartment = '';
            } else {
                state.user.city = item.city;
                state.user.street = item.street;
                state.user.appartment = item.appartment;
            }
        }
    },
    actions: {
        addCartProduct({commit}, product){
            commit('addCartProduct', product);
        },
        removeCartProduct({commit},id){
            commit('removeCartProduct',id)
        },
        increase({commit},id){
            commit('increase',id)
        },
        decrease({commit},id){
            commit('decrease',id)
        },
        setDeliveryMethod({commit}, item){
            commit('setDeliveryMethod', item);
        },
        setUserAddress({commit}, item){
            commit('setUserAddress', item);
        },
        setUserInfo({commit}, item){
            commit('setUserInfo', item);
        },
    },
    getters: {
        getProducts: state => {
            return state.products;
        },
        getCart: state => {
            return state.cart;
        },
        getSelectedDeliveryMethod: state => {
            return state.user.delivery;
        },
        getDeliveryMethods: state => {
            return state.deliveryMethods;
        },
        getTotalPrice: state => {
            let totalPrice = 0;

            state.cart.map((product) => {
                totalPrice += product.price * product.quantity
            });

            return totalPrice;
        },
        getTotalCount: state => {
            let totalCount = 0;

            state.cart.map((product) => {
                totalCount += product.quantity;
            });

            return totalCount;
        },
        getUser: (state, getters) => {
            state.user.products = getters.getCart;
            return state.user;
        },
    },
});