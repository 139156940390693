<template>
	<div class="page with-padding">
		<div class="container">
			<div class="page__header">
				<h1 class="page__title">
					Подтвердите заказ
				</h1>
			</div>
			<div class="order">
				<div class="order__item">
					<h2 class="order__title">
						Состав заказа
					</h2>
					<ul class="order-products">
						<li
							class="order-products__item"
							v-for="(product, index) in products"
							:key="index"
						>
							<OrderCard
								:product-code="product.code"
								:image="product.image"
								:subtitle="product.subtitle"
								:title="product.title"
								:quantity="product.quantity"
								:price="product.price"
								:event-remove="false"
								:isNotLink="true"
							/>
						</li>
					</ul>
				</div>
				<div class="order__item">
					<h2 class="order__title">
						Способ доставки
					</h2>
					<div class="delivery">
						<div class="delivery-card">
							<div class="delivery-card__title">
								{{ deliveryMethod.name }}
								<div>
									{{ deliveryMethod.address }}
								</div>
							</div>
							<div class="delivery-card__info">
								<div class="delivery-card__price">
									{{ deliveryMethod.price }}
								<span class="" v-if="deliveryMethod.price !== 'Бесплатно'">
									р.
								</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="order__item">
					<h2 class="order__title">
						Контактная информация
					</h2>
					<div class="order-info">
						<BaseField
							:isFocusDisabled="true"
							:readonly="true"
							field-type="text"
							field-name="user_name"
							:model-value="user.name"
						/>
						<BaseField
							:isFocusDisabled="true"
							:readonly="true"
							field-type="tel"
							inputmode="tel"
							field-name="user_phone"
							:model-value="user.phone"
						/>
						<BaseField
							:isFocusDisabled="true"
							:readonly="true"
							field-type="email"
							field-name="user_email"
							:model-value="user.email"
						/>
						<template v-if="deliveryMethod.type !== 'pickup'">
							<BaseField
									:isFocusDisabled="true"
									:readonly="true"
									field-type="text"
									field-name="user_city"
									:model-value="user.city"
							/>
							<BaseField
									:isFocusDisabled="true"
									:readonly="true"
									field-type="text"
									field-name="user_street"
									:model-value="user.street"
							/>
							<BaseField
									:isFocusDisabled="true"
									:readonly="true"
									field-type="text"
									field-name="user_appartment"
									:model-value="user.appartment"
							/>
						</template>
					</div>
				</div>
				<Cart
					link-text="Оплатить"
					:is-button="true"
					@click.prevent="onPay"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import serviceTelegram from "@/core/services/serviceTelegram";
import serviceOrder from "@/core/services/serviceOrder";

import BaseIcon from "@/components/Base/BaseIcon";
import Cart from "@/components/Cart";
import OrderCard from "@/components/OrderCard";
import BaseField from "@/components/Base/BaseField";
import BaseRadioButton from "@/components/Base/BaseRadioButton";

export default {
	name: "OrderResult",
	components: {
		BaseIcon,
		Cart,
		OrderCard,
		BaseField,
		BaseRadioButton
	},
	setup() {
		const store = useStore();

		const user = computed(() => store.state.user)
		const products = computed(() => user.value.products);
		const deliveryMethod = computed(() => user.value.delivery);

		const onPay = () => {
			serviceTelegram.openLink(store.state.urlPay);
		};

		return {
			user,
			products,
			deliveryMethod,
			onPay
		}
	}
}
</script>