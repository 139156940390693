<template>
	<BasePreloader
		:is-loading="isLoading"
	/>
	<div
		ref="catalogPage"
		class="page page_catalog"
		:style="{ paddingBottom: showCart ? '96px' : '0'}"
	>
		<div class="container">
			<div class="page__header">
				<h1 class="page__title">
					Выбери свой вкус
				</h1>
			</div>
			<div class="catalog">
				<div class="catalog__table">
					<div
						class="catalog__wrapper"
						v-for="(product, index) in products"
						:key="index"
					>
						<CatalogCard
							:product-code="product.code"
							:is-add-cart="product.isCart"
							:count="product.quantity"
							:image="product.image"
							:price="product.price"
							:subtitle="product.subtitle"
							:title="product.title"
							@onClickButton="addCartProduct(product)"
							@onClickIncrease="increaseProduct(product.id)"
							@onClickDecrease="decreaseProduct(product.id)"
						/>
					</div>
				</div>
			</div>
			<Cart
				link-text="Оформить заказ"
				:link-url="{ name: 'OrderList'}"
				:class="[
					{'is-hidden': !showCart},
					{'is-show': showCart}
				]"
			/>
		</div>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import {computed, ref} from "vue";

import serviceTelegram from "@/core/services/serviceTelegram"

// Import components =====>
import BasePreloader from "@/components/Base/BasePreloader";
import CatalogCard from "@/components/CatalogCard";
import Cart from "@/components/Cart";

export default {
	name: "Catalog",
	components: {
		BasePreloader,
		CatalogCard,
		Cart
	},
	setup() {
		const store = useStore();
		const isLoading = ref(true);

		const cart = ref(store.state.cart);
		const isCart = ref(false);
		const showCart = computed(() => {
			return store.getters.getCart.length > 0;
		})

		const products = computed(() => store.getters.getProducts);

		setTimeout(() => {
			isLoading.value = false;
		}, 600);

		const addCartProduct = (product) => {
			product.quantity = 1;
			let items = { ...product };
			store.dispatch('addCartProduct', items)
		}

		const increaseProduct = (id) => {
			store.dispatch("increase", id);
		}

		const decreaseProduct = (id) => {
			store.dispatch("decrease", id);
		}

		return {
			store,
			cart,
			isCart,
			showCart,
			products,
			addCartProduct,
			increaseProduct,
			decreaseProduct,
			isLoading
		}
	}
}
</script>
