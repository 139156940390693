<template>
	<div class="cart">
		<div class="cart__text">
			<div class="cart__count">
				Выбрано {{ totalCount }} шт. на сумму
			</div>
			<div class="cart__price">
				{{ setTotalPrice }} руб.
			</div>
		</div>
		<BaseButton
			v-if="isButton"
			:text="linkText"
		/>
		<router-link
			v-else
			:to="linkUrl"
			class="btn-primary"
		>
			{{ linkText }}
		</router-link>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import BaseButton from "@/components/Base/BaseButton";

export default {
	name: "Cart",
	components: {
		BaseButton
	},
	props: {
		linkUrl: {
			type: Object,
			default: () => ({})
		},
		linkText: {
			type: String,
			default: ''
		},
		isButton: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const store = useStore()

		const cart = computed(() => store.getters.getCart);
		const totalPrice = computed(() => Number(store.getters.getTotalPrice));
		const totalCount = computed(() => store.getters.getTotalCount);
		const deliveryPrice = computed(() => {
			if (store.getters.getSelectedDeliveryMethod.price === 'Бесплатно') {
				return 0;
			} else {
				return Number(store.getters.getSelectedDeliveryMethod.price);
			}
		});

		const setTotalPrice = computed(() => {
			const price = totalPrice.value + deliveryPrice.value;
			return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		});

		return {
			cart,
			totalPrice,
			totalCount,
			deliveryPrice,
			setTotalPrice
		}
	}
}
</script>