<template>
	<div class="page with-padding">
		<div class="container">
			<div class="page__header">
				<h1 class="page__title">
					Оформление заказа
				</h1>
			</div>
			<BasePreloader
				:isLoading="orderFormation"
			/>
			<form class="order" @submit.prevent="onSubmitOrder">
				<div class="order-info">
					<BaseField
						:required="true"
						field-placeholder="Ваше имя"
						field-type="text"
						field-name="user_name"
						v-model="user.name"
						@change="setUser"
					/>
					<BaseField
						:required="true"
						field-placeholder="Номер телефона"
						field-type="tel"
						inputmode="tel"
						field-name="user_phone"
						v-model="user.phone"
						pattern="^\+?[0-9]\s\(([0-9]{3})\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$"
						v-mask="'+7 (###) ###-##-##'"
						@change="setUser"
					/>
					<BaseField
						:required="true"
						field-placeholder="E-mail"
						field-type="email"
						field-name="user_email"
						v-model="user.email"
						@change="setUser"
					/>
					<template v-if="getDeliveryType !== 'pickup'">
						<BaseField
								:required="true"
								field-placeholder="Город"
								field-type="text"
								field-name="user_city"
								v-model="user.city"
								@change="setUser"
						/>
						<BaseField
								:required="true"
								field-placeholder="Улица и дом"
								field-type="text"
								field-name="user_house"
								v-model="user.street"
								@change="setUser"
						/>
						<BaseField
								field-placeholder="Номер квартиры"
								field-type="text"
								inputmode="numeric"
								field-name="user_appartment"
								v-model="user.appartment"
								@change="setUser"
						/>
					</template>
				</div>
				<Cart
					link-text="Оформить"
					:is-button="true"
				/>
			</form>
		</div>
	</div>
</template>

<script>
import {computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import serviceTelegram from "@/core/services/serviceTelegram";
import serviceOrder from "@/core/services/serviceOrder";

import BasePreloader from "@/components/Base/BasePreloader";
import BaseIcon from "@/components/Base/BaseIcon";
import Cart from "@/components/Cart";
import OrderCard from "@/components/OrderCard";
import BaseField from "@/components/Base/BaseField";
import BaseRadioButton from "@/components/Base/BaseRadioButton";

export default {
	name: "Order",
	components: {
		BasePreloader,
		BaseIcon,
		Cart,
		OrderCard,
		BaseField,
		BaseRadioButton
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const requestData = reactive({});
		const orderFormation = ref(false);

		serviceTelegram.setClickBackButton(() => {
			router.push({ name: 'OrderDelivery'});
		});

		const products = computed(() => store.getters.getCart);

		const getDelivery = computed(() => store.getters.getSelectedDeliveryMethod);

		const getDeliveryName = computed(() => {
			const { name = '' } = getDelivery.value;
			return name;
		});

		const getDeliveryPrice = computed(() => {
			const { price = '' } = getDelivery.value;
			return price;
		});

		const getDeliveryType = computed(() => {
			const { type = '' } = getDelivery.value;
			return type;
		})

		const user = reactive(store.state.user);

		const setUser = () => {
			store.dispatch('setUserInfo', user);
		}

		const removeCartProduct = (id) => {
			store.dispatch("removeCartProduct", id);
			if (products.value.length <= 0) {
				router.push({ name: 'Home' });
			}
		};

		const onSubmitOrder = () => {
			const deliveryPrice = () => {
				if (getDeliveryPrice.value === 'Бесплатно') {
					return 0;
				} else {
					return Number(getDeliveryPrice.value);
				}
			}

			orderFormation.value = true;

			store.state.user.amount = (Number(store.getters.getTotalPrice) + Number(deliveryPrice())) * 100;

			serviceOrder
				.postOrder(store.getters.getUser)
				.then(response => {
					const { data = {} } = response.data;
					Object.assign(requestData, data);

					orderFormation.value = false;
					store.state.urlPay = data.url;
					store.state.user.orderId = data.order_id;
					router.push({ name: 'OrderResult'});
				})
				.catch((error) => {
				});
		};

		return {
			products,
			user,
			orderFormation,
			removeCartProduct,
			getDelivery,
			getDeliveryPrice,
			getDeliveryName,
			getDeliveryType,
			setUser,
			onSubmitOrder,
		}
	}
}
</script>
