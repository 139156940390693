<template>
	<div class="page with-padding">
		<div class="container">
			<div class="page__header">
				<h1 class="page__title">
					Состав заказа
				</h1>
			</div>
			<ul class="order-products">
				<li
					class="order-products__item"
					v-for="(product, index) in products"
					:key="index"
				>
					<OrderCard
						:product-code="product.code"
						:image="product.image"
						:subtitle="product.subtitle"
						:title="product.title"
						:quantity="product.quantity"
						:price="product.price"
						@onRemoveCartProduct="removeCartProduct(product.id)"
					/>
				</li>
			</ul>
			<div class="cart">
				<router-link :to="{ name: 'OrderDelivery'}" class="btn-primary">
					Оформить заказ
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import OrderCard from "@/components/OrderCard";
import {useStore} from "vuex";
import {useRouter} from "vue-router/dist/vue-router";
import serviceTelegram from "@/core/services/serviceTelegram";
import {computed} from "vue";

export default {
	name: "OrderList",
	components: {
		OrderCard
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		serviceTelegram.setClickBackButton(() => {
			router.push({ name: 'Home'});
		});

		const products = computed(() => store.getters.getCart);

		const removeCartProduct = (id) => {
			store.dispatch("removeCartProduct", id);
			if (products.value.length <= 0) {
				router.push({ name: 'Home' });
			}
		};

		return {
			removeCartProduct,
			products,
		}
	}
}
</script>