import serviceApi from "@/core/serviceApi";

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Token': '07SXMJJgCaM5AJNgplEJzBTzYQ5fVWWQ'
    }
}

export default {
    async postOrder(content) {
        const userPhone = content.phone.replace(/\D/g, '');
		let address = '';
		if (content.city !== '') {
			address += content.city;
		}
		if (content.street !== '') {
			address += ', ' + content.street;
		}
		if (content.appartment !== '') {
			address += ', ' + content.street;
		}

		return await serviceApi().post('/api/v1/payments', {
			payer_name: content.name,
			payer_phone: userPhone,
			payer_email: content.email,
			amount: content.amount,
			basket: content.products,
			delivery: content.delivery,
			address: address
		}, headers)
    },
	async validationOrder(orderId, type) {
		return await serviceApi().put(`/api/v1/orders/${orderId}`, {
			result: type
		}, headers);
	}
}
