<template>
	<div class="page page_catalog-detail">
		<div class="container">
			<div class="catalog-detail">
				<div class="catalog-detail__image">
					<img :src="getImage" alt="Product preview">
				</div>
				<div class="catalog-detail__description">
					{{ getDescription }}
				</div>
			</div>
			<div class="catalog-detail-cart">
				<div class="catalog-detail-cart__text">
					<h3 class="catalog-detail-cart__title">
						{{ getSubtitle }}
						{{ getTitle }}
					</h3>
					<div class="catalog-detail-cart__price">
						{{ totalPrice }} руб.
					</div>
				</div>
				<div class="catalog-detail-cart__interactions">
					<BaseCounter
						:count="getQuantity"
						@increase="increaseProduct(getProductId)"
						@decrease="decreaseProduct(getProductId)"
					/>
					<BaseButton
						@clickHandler="addCartProduct(product)"
						text="В корзину"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import serviceTelegram from "@/core/services/serviceTelegram";

import BaseButton from "@/components/Base/BaseButton";
import BaseCounter from "@/components/Base/BaseCounter";

export default {
	name: "CatalogDetail",
	components: {
		BaseButton,
		BaseCounter
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		// serviceTelegram.setBackButton(true);
		serviceTelegram.setClickBackButton(() => {
			router.push({ name: 'Home'});
		});

		const products = computed(() => store.getters.getProducts);
		const cart = computed(() => store.getters.getCart);

		const productCode = ref(useRoute().params.id);
		const product = computed(() => products.value.find(item => item.code === productCode.value));

		const isCart = () => {
			if (!product.value.isCart) {
				product.value.quantity = 1;
			}
		}

		isCart();

		const getProductId = computed(() => {
			const { id = '' } = product.value
			return id;
		});

		const getTitle = computed(() => {
			const { title = '' } = product.value
			return title;
		});

		const getSubtitle = computed(() => {
			const { subtitle = '' } = product.value
			return subtitle;
		});

		const getDescription = computed(() => {
			const { description = '' } = product.value
			return description;
		});

		const getImage = computed(() => {
			const { image = '' } = product.value
			return image;
		});

		const getQuantity = computed(() => {
			const { quantity = 0 } = product.value;
			return quantity;
		});

		const getPrice = computed(() => {
			const { price = '' } = product.value;
			return price;
		});

		const totalPrice = computed(() => {
			return getPrice.value * getQuantity.value;
		});

		const addCartProduct = (product) => {
			if (product.quantity === 0) {
				product.quantity = 1;
			}
			let items = { ...product };
			store.dispatch('addCartProduct', items);
			router.push({ name: 'Home' });
		}

		const removeCartProduct = (id) => {
			store.dispatch("removeCartProduct", id);
		}

		const increaseProduct = (id) => {
			store.dispatch("increase", id);
		}

		const decreaseProduct = (id) => {
			store.dispatch("decrease", id);
		}

		return {
			products,
			cart,
			product,
			totalPrice,
			getProductId,
			getTitle,
			getSubtitle,
			getImage,
			getDescription,
			increaseProduct,
			decreaseProduct,
			addCartProduct,
			removeCartProduct,
			getPrice,
			getQuantity,
			isCart
		}
	}
}
</script>