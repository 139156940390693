<template>
  <router-view/>
</template>
<script>
import {computed, watch} from "vue";
import { useStore } from "vuex";
import {useRoute} from "vue-router/dist/vue-router";
import serviceTelegram from "@/core/services/serviceTelegram";

export default {
	setup() {
		const store = useStore()
		const route = useRoute();
		const deliveryMethods = computed(() => store.getters.getDeliveryMethods);

		const setDefaultDeliveryMethod = () => {
			store.dispatch('setDeliveryMethod', deliveryMethods.value[0]);
		}

		setDefaultDeliveryMethod();

		watch(() => route.name, () => {
			if (route.name === 'Home') {
				return serviceTelegram.setBackButton(false);
			} else {
				return serviceTelegram.setBackButton(true);
			}
		});

		return {
			deliveryMethods
		}
	}
}
</script>