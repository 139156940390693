const tg = window.Telegram.WebApp;

export default {
    initData() {
        return tg.initData;
    },
    setBackButton(data) {
        return tg.BackButton.isVisible = data;
    },
    setClickBackButton(callback) {
        return tg.onEvent('backButtonClicked', callback);
    },
    openLink(url) {
        return tg.openLink(url);
    },
	showPopup(params, callback) {
		// params[, callback]
		tg.showPopup(params, callback)
	},
	setMainButton(text) {
		tg.MainButton.text = text;
		tg.MainButton.isVisible = true;
		tg.MainButton.onClick(() => {
			tg.openLink('https://pay1time.com/pay/12506748');
		})
	}
}
