import { createRouter, createWebHistory } from 'vue-router'

import CatalogPage from "@/views/CatalogPage";
import CatalogDetail from "@/views/CatalogDetail";

import OrderList from "@/views/OrderList";
import OrderDelivery from "@/views/OrderDelivery";
import OrderPage from "@/views/OrderPage";
import OrderResult from "@/views/OrderResult";

import GratitudePage from "@/views/GratitudePage";
import ErrorPage from "@/views/ErrorPage";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: CatalogPage,
  },
  {
    path: '/:id',
    name: 'CatalogDetail',
    component: CatalogDetail,
  },
  {
    path: '/order/',
    name: 'OrderList',
    component: OrderList,
  },
  {
    path: '/order/delivery',
    name: 'OrderDelivery',
    component: OrderDelivery
  },
  {
    path: '/order/info',
    name: 'Order',
    component: OrderPage,
  },
  {
    path: '/order/result',
    name: 'OrderResult',
    component: OrderResult,
  },
  {
    path: '/gratitude',
    name: 'Gratitude',
    component: GratitudePage,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
