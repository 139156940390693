<template>
	<button
		class="btn-primary"
		@click="clickHandler"
	>
		{{ text }}
	</button>
</template>

<script>
export default {
	name: "BaseButton",
	props: {
		text: {
			type: String,
			default: ''
		}
	},
	setup(props, { emit }) {
		const clickHandler = () => {
			emit('clickHandler');
		}

		return {
			clickHandler
		}
	}
}
</script>