<template>
	<div class="page with-padding">
		<div class="container">
			<div class="page__header">
				<h1 class="page__title">
					Выберите способ доставки
				</h1>
			</div>
			<div class="delivery">
				<div
					class="delivery__item"
					v-for="(item, index) in deliveryMethods"
					:key="index"
				>
					<BaseRadioButton
						group-name="delivery_method"
						:title="item.name"
						:address="item.address"
						:price="item.price"
						:value="item.name"
						v-model="selectedDeliveryMethod"
					/>
				</div>
			</div>
			<div class="cart">
				<router-link :to="{ name: 'Order' }" class="btn-primary">
					Далее
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import { useRouter } from "vue-router";

import serviceTelegram from "@/core/services/serviceTelegram";

import BaseRadioButton from "@/components/Base/BaseRadioButton";
import BaseButton from "@/components/Base/BaseButton";
import BaseField from "@/components/Base/BaseField";

export default {
	name: "OrderDelivery",
	components: {
		BaseRadioButton,
		BaseButton,
		BaseField
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		serviceTelegram.setClickBackButton(() => {
			router.push({ name: 'OrderList'});
		});

		const deliveryMethods = computed(() => store.getters.getDeliveryMethods);

		const selectedDeliveryMethod = ref(store.getters.getSelectedDeliveryMethod.name);

		watch(() => selectedDeliveryMethod.value, () => {
			return deliveryMethods.value.map(item => {
				if (item.name === selectedDeliveryMethod.value) {
					store.dispatch('setDeliveryMethod', item);
				}
			});
		});

		return {
			deliveryMethods,
			selectedDeliveryMethod,
		}
	}
}
</script>