<template>
	<label
		:class="[
			'base-radio',
			{ 'is-checked': isChecked }
		]"
	>
		<input
			type="radio"
	        class="base-radio__input"
			:name="groupName"
			:checked="isChecked && checked"
			:value="value"
			@change="onChange"
		>
		<div class="base-radio__title">
			{{ title }}
			<span v-if="address">
				{{ address }}
			</span>
		</div>
		<div class="base-radio__price">
			{{ price }}
			<template v-if="price !== 'Бесплатно'">
				руб.
			</template>
		</div>
	</label>
</template>

<script>
import { computed, toRefs } from "vue";

export default {
	name: "BaseRadioButton",
	props: {
		value: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		address: {
			type: String,
			default: ''
		},
		price: {
			type: String,
			default: ''
		},
		groupName: {
			type: String,
			default: ''
		},
		modelValue: {
			type: String,
			default: undefined
		},
		checked: {
			type: Boolean,
			default: false
		}
	},
	setup(props, { emit }) {
		const { modelValue } = toRefs(props);
		const { value } = toRefs(props);

		const isChecked = computed(() => {
			return modelValue.value === value.value
		})

		const onChange = (event) => {
			emit('update:modelValue', event.target.value);
		}

		return {
			onChange,
			isChecked,
		}
	}
}
</script>