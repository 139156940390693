<template>
	<div class="page gratitude">
		<div class="container">
			<div class="gratitude__title">
				Произошла ошибка при оплате!
			</div>
			<div class="gratitude__description">
				Попробуйте снова через несколько минут
			</div>
			<router-link :to="{ name: 'Home'}" class="btn-primary">
				В магазин
			</router-link>
		</div>
	</div>
</template>

<script>
import {onMounted} from "vue";
import {useRoute} from "vue-router/dist/vue-router";

import serviceOrder from "@/core/services/serviceOrder";

export default {
	name: "ErrorPage",
	setup() {
		const route = useRoute();

		onMounted(() => {
			serviceOrder
				.validationOrder(route.query.orderId, 'fail')
				.then(response => {
					const { data = {} } = response.data;

					return data;
				})
				.catch((error) => {
					console.log(error);
				});
		})
	}
}
</script>